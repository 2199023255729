import React from "react";

import { useTranslation } from "react-i18next";

import { CustomParagraph } from "../../../../components/customs";

import "./styles.css";

interface PaymentInformationProps {
  suffix: string | undefined;
}

export const PaymentInformation = ({ suffix }: PaymentInformationProps) => {
  const { t } = useTranslation();

  return (
    <CustomParagraph className="payment-info-text">
      {t("home.subheading_prefix")} {suffix} {t("home.subheading_postfix")}
    </CustomParagraph>
  );
};
