import React from "react";

import { useTranslation } from "react-i18next";

import { ToastContainer } from "react-toastify";

export const ToastLayout = () => {
  const { i18n } = useTranslation();

  const isRTL = ["ar"].includes(i18n.language);

  return <ToastContainer rtl={isRTL} />;
};
