import EnglishFlag from "../../../assets/images/US.png";
import ChinaFlag from "../../../assets/images/CN.png";
import ArabicFlag from "../../../assets/images/PS.png";
import HonkKongFlag from "../../../assets/images/HK.png";

export type Language = {
  label: string;
  flagSrc: string;
  key: string;
  id: number;
};

export const LANGUAGE_LIST: Language[] = [
  {
    label: "简体中文",
    flagSrc: ChinaFlag,
    key: "cn",
    id: 0,
  },
  {
    label: "EN",
    flagSrc: EnglishFlag,
    key: "en",
    id: 1,
  },
  {
    label: "عربي",
    flagSrc: ArabicFlag,
    key: "ar",
    id: 2,
  },
  {
    label: "繁體中文",
    flagSrc: HonkKongFlag,
    key: "hk",
    id: 3,
  },
];
