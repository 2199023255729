
import Web3 from "web3";
import { toastError } from "../../../utils/toast/index.tsx";
import {t} from "i18next";

export async function ethTransaction(
    donatorAddress: string,
    receiverAddress: string,
    donationAmount: number
) {
    // web3 contract重试写法
    const executeTransaction = async () => {
        try {
            const web3 = new Web3(window.ethereum);
            const usdtContractAddress = "0x55d398326f99059ff775485246999027b3197955"; //BEP20
            const usdtAbi = [{"constant":false,"inputs":[{"name":"_spender","type":"address"},{"name":"_value","type":"uint256"}],"name":"approve","outputs":[{"name":"","type":"bool"}],"payable":false,"stateMutability":"nonpayable","type":"function"}] as const;
            const usdtContract = new web3.eth.Contract(usdtAbi, usdtContractAddress);

            const donationAmountStr = donationAmount.toString() + "000000";
            const data = await usdtContract.methods.approve(receiverAddress, donationAmountStr).encodeABI()

            const gasPrice = await web3.eth.getGasPrice();
            const gasLimit = await web3.eth.estimateGas({
                from: donatorAddress,
                to: usdtContractAddress,
                data: data
            });

            const tx = {
              from: donatorAddress,
              to: usdtContractAddress,
              data: data,
              gasPrice: gasPrice, // 替换为适当的 gas 限制
              gasLimit: gasLimit, // 替换为适当的 gasPrice
            };

            const transactionHash = await sendTransaction(web3, tx)
          const res = {
            transactionHash
          }        
            return res
        } catch (error) {
          console.log(error.message)
            toastError(t("transactions.sign_transaction"));
        }
    };
    // 执行交易
   return await executeTransaction();
}

function sendTransaction(web3: any, tx: any) {
  return new Promise((resolve, reject) => {
    web3.eth.sendTransaction(tx).on("transactionHash", (hash:any) => {
      resolve(hash)
    }).on("error",(error:any) => {
      reject(error)
    })
  })  
}
