import React from "react";

import { Language } from "./utils";

import "./styles.css";

interface LanguageSwitchContent {
  languageList: Language[];
  onLanguageChange: (langKey: string) => void;
}

export const LanguageSwitchContent = ({
  languageList,
  onLanguageChange,
}: LanguageSwitchContent) => {
  return (
    <div className="language-content">
      {languageList.map((lang: Language) => (
        <div
          key={lang.id}
          className="language-content-item"
          onClick={() => onLanguageChange(lang.key)}
        >
          <img
            className="language-flag"
            src={lang.flagSrc}
            alt={`${lang.label} Image`}
          />
          <span className="language-label">{lang.label}</span>
        </div>
      ))}
    </div>
  );
};
