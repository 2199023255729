import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";
import {initReactI18next} from "react-i18next";

const resources = {
    en: {
        translation: require("./locales/en.json"),
    },
    cn: {
        translation: require("./locales/cn.json"),
    },
    ar: {
        translation: require("./locales/ar.json"),
    },
    hk: {
        translation: require("./locales/hk.json"),
    },
};

export const supportedLngs = Object.keys(resources);

const detection = {
    order: ["path"],
    lookupFromPathIndex: 0,
};

i18n
    .use(detector)
    .use(backend)
    .use(initReactI18next)
    .init({
        detection,
        supportedLngs,
        resources,
        fallbackLng: "cn",
        keySeparator: ".",
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
