import React from "react";

import { useTranslation } from "react-i18next";

import { GreyContainer } from "../../../../components/wrappers";

import "./styles.css";

export const Heading = () => {
  const { t } = useTranslation();

  return (
    <div className="heading-container">
      <GreyContainer align="center">
        <h2 className="heading-text">{t("home.heading")}</h2>
      </GreyContainer>
    </div>
  );
};
