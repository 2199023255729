import React from "react";

import { useTranslation } from "react-i18next";

import "./styles.css";

export const FooterText = () => {
  const { t } = useTranslation();

  return (<div className="homepage-footer-text">
    <div >{t("home.footer1")}</div>
    <div >{t("home.footer2")}</div>
  </div>)
}
