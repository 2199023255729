import React, { useEffect } from "react";

import clsx from "clsx";

import { useTranslation } from "react-i18next";

import { Outlet, useNavigate, useParams } from "react-router";

import { supportedLngs } from "../../i18n";

import "./styles.css";

export const AppLayout = () => {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { language } = useParams();
  const currentPath = location.pathname;

  const isRTL = ["ar"].includes(i18n.language);

  const className = clsx({ "app-layout": true, "is-rtl": isRTL });

  useEffect(() => {
    const isValidLanguage = language && supportedLngs.includes(language);
    const shouldChangeLanguage = isValidLanguage && language !== i18n.language;
    
    // if (!isValidLanguage) {
    //   navigate("/" + i18n.language + currentPath + '?id=11', { replace: true });
    // }

    if (shouldChangeLanguage) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n, supportedLngs, currentPath, navigate]);

  return (
    <div className={className}>
      <Outlet />
    </div>
  );
};
