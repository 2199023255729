import React from "react";

import { useTranslation } from "react-i18next";

import { Button } from "../../../../components/buttons";

import "./styles.css";

interface ISubmitButton {
  onSubmit: () => void;
}

export const SubmitButton = ({ onSubmit }: ISubmitButton) => {
  const { t } = useTranslation();

  return (
    <div className="submit-button-container">
      <Button className="submit-button" onClick={onSubmit}>
        {t("home.connect_wallet")}
      </Button>
    </div>
  );
};
