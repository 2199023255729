import React, {useEffect, useRef, useState} from "react";

import { useTranslation } from "react-i18next";

import { CurrencyDataType } from "../../../../constant";

import { ReactComponent as ActiveIcon } from "../../../../assets/svgs/active.svg";
import { ReactComponent as InactiveIcon } from "../../../../assets/svgs/inactive.svg";
import { ReactComponent as PromptIcon } from "../../../../assets/svgs/question-mark-circled.svg";
import { Tooltip } from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css'
import "./styles.css";

interface TabContentProps {
  tabContentData: CurrencyDataType[];
  selectedOptionIndex: number | null;
  handleOptionChange: (index: number, item: CurrencyDataType) => void;
}

export const TabContent = ({
  tabContentData,
  selectedOptionIndex,
  handleOptionChange,
}: TabContentProps) => {
  const [tooltipOpenIndex, setTooltipOpenIndex] = useState<number | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const handleTooltipToggle = (index: number, event: any) => {
    event.stopPropagation();
    if (tooltipOpenIndex === index) {
      setTooltipOpenIndex(null);
    } else {
      setTooltipOpenIndex(index);
    }
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
      setTooltipOpenIndex(null);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="payment-method-section">
      {tabContentData.map((item: CurrencyDataType, index) => {
        const paymentMethodClassName =
          index !== tabContentData.length - 1 ? "apply-class" : "";

        return (
          <div
            onClick={() => handleOptionChange(index, item)}
            className={`payment-method ${paymentMethodClassName}`}
            key={item.id}
          >
            <div className="payment-method-leftside-content">
              <img src={item.image} alt={`img-${index}`} />
              <div>
                <div className="payment-method-title-container">
                  {item.title}
                  {/* {item?.isAvailable ? <ActiveIcon /> : <InactiveIcon />} */}
                  {item.showPrompt &&
                      <PromptIcon
                          data-tooltip-id={`tooltip-icon-${index + 1}`}
                          id={`tooltip-icon-${index + 1}`}
                          style={{ cursor: 'pointer' }}
                          onClick={(event: any) => handleTooltipToggle(index , event)}
                      />
                  }
                  <Tooltip
                      id={`tooltip-icon-${index + 1}`}
                      place="right"
                      openOnClick={true}
                      variant="info"
                      style={{ zIndex: "99", width: "230px" }}
                      isOpen={tooltipOpenIndex === index}
                  >
                    <div ref={tooltipRef}>
                      {t(item.id === 8 ? "home.coinbase_wallet_prompt" : "home.wallet_prompt")}
                    </div>
                  </Tooltip>
                </div>
                <div>
                  {t("home.token_type")}
                  <span>{" - " + item.suffix}</span>
                </div>
              </div>
            </div>

            <div className="payment-method-input">
              <label key={index}>
                <input
                  type="radio"
                  value={index}
                  checked={selectedOptionIndex === index}
                  onChange={() => handleOptionChange(index, item)}
                />
                <div className="radio-btn-inner"></div>
              </label>
            </div>
          </div>
        );
      })}
    </div>
  );
};
