import React from "react";

import { useTranslation } from "react-i18next";

import { PaymentHeading } from "../PaymentHeading";
import { InputLabel } from "../../../../components/inputs/InputLabel";
import { PaymentAdornmentInput } from "./components/PaymentAdornmentInput";
import { Button } from "../../../../components/buttons";

import "./styles.css";

interface PaymentFormProps {
  address?: string;
  quantity: string;
  submitLoading?: boolean;
  handleQuantityChange: (quantity: string) => void;
  onSubmit: () => void;
  onDisconnect: () => void;
}

export const PaymentForm = ({
  address,
  quantity,
  submitLoading = false,
  handleQuantityChange,
  onSubmit,
  onDisconnect,
}: PaymentFormProps) => {
  const { t } = useTranslation();

  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleQuantityChange(e.target.value);
  };

  return (
    <div className="payment-form-container">
      <PaymentHeading />
      <div className="payment-form-inputs">
        <InputLabel
          className="payment-form-input"
          type="text"
          placeholder={t("payment.account_input_placeholder")}
          label={t("payment.account_input_label")}
          htmlFor={"account"}
        />
        <PaymentAdornmentInput
          className="payment-form-input with-adornment"
          label={t("payment.amount_input_label")}
          type="number"
          placeholder={t("payment.amount_input_placeholder")}
          min={0}
          value={quantity}
          step={"1"}
          htmlFor={"amount"}
          onChange={onQuantityChange}
        />
        {address && (
          <InputLabel
            label={t("payment.address_input_label")}
            className="payment-form-input"
            placeholder={t("payment.address_input_placeholder")}
            value={address}
            disabled
          />
        )}
        <div className="payment-form-buttons">
          <Button
            isLoading={submitLoading}
            className="payment-form-btn"
            variant="contained"
            onClick={onSubmit}
          >
            {t("payment.pay_button")}
          </Button>
          <Button
            className="payment-form-btn"
            variant="danger"
            onClick={onDisconnect}
          >
            {t("payment.disconnect_button")}
          </Button>
        </div>
      </div>
    </div>
  );
};
