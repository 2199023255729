import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

import { ToastLayout } from "./layouts/ToastLayout/ToastLayout";
import { HomePageRedirect } from "./routes/HomePageRedirect";
import { AppLayout } from "./layouts/AppLayout/AppLayout";
import { FullScreenLoading } from "./components/loadings/FullScreenLoading/FullScreenLoading";
import { PaymentProvider } from "./providers/PaymentProvider";
import { WagmiConfigProvider } from "./providers/WagmiConfigProvider";
import { ConnectionTypeProvider } from "./providers/ConnectionTypeProvider";
import { LanguageSwitch } from "./components/poppers";

import PaymentPage from "./pages/Payment/PaymentPage";
import HomePage from "./pages/Homepage/HomePage";
import {Navigate} from "react-router";

const App: React.FC = () => {
  return (
    <>
      <ToastLayout />
      <WagmiConfigProvider>
        <ConnectionTypeProvider>
          <PaymentProvider>
            <LanguageSwitch />
            <Suspense fallback={<FullScreenLoading />}>
              {/* <Routes>
                <Route path="/" element={<HomePageRedirect />} />
                <Route path=":language" element={<AppLayout />}>
                  <Route index element={<HomePage />} />
                  <Route path="payment" element={<PaymentPage />} />
                </Route>
              </Routes> */}
              <Routes>
                <Route path="" element={<AppLayout />}>
                  <Route path='/' element={<HomePage />} />
                  <Route path="/payment" element={<PaymentPage />} />
                  <Route path="/*"  element={<Navigate to="/"/>} />
                </Route>
              </Routes>
            </Suspense>
          </PaymentProvider>
        </ConnectionTypeProvider>
      </WagmiConfigProvider>
    </>
  );
};

export default App;
