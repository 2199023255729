import React from "react";

import { useTranslation } from "react-i18next";

import { Navigate } from "react-router";

export const HomePageRedirect = () => {
  const { i18n } = useTranslation();

  return <Navigate to={`/`} />;
  // return <Navigate to={`/${i18n.language}`} />;
};
