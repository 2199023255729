import React, { useMemo, useState } from "react";

import { useLocation, useNavigate } from "react-router";

import { useTranslation } from "react-i18next";

import { Popper } from "../../popper/Popper";

import { LanguageSwitchContent } from "./LanguageSwitchContent";
import { LanguageSwitchTrigger } from "./LanguageSwitchTrigger";

import { extractPath } from "../../../utils/paths";
import { LANGUAGE_LIST, Language } from "./utils";

export const LanguageSwitchPopper = () => {
  const navigate = useNavigate();
  const [isOpened, setIsOpened] = useState(false);
  const location = useLocation();

  const { i18n } = useTranslation();

  const selectedLanguage = useMemo(() => {
    return LANGUAGE_LIST.find((lang: Language) => lang.key === i18n.language);
  }, [i18n.language, LANGUAGE_LIST]) as Language;

  const onLanguageChange = (langKey: string) => {
    i18n.changeLanguage(langKey);
    setIsOpened(false);
    // navigate("/" + langKey + extractPath(location.pathname));
  };

  return (
    <Popper
      open={isOpened}
      align="end"
      trigger={
        <LanguageSwitchTrigger
          flagSrc={selectedLanguage?.flagSrc}
          label={selectedLanguage?.label}
        />
      }
      content={
        <LanguageSwitchContent
          languageList={LANGUAGE_LIST}
          onLanguageChange={onLanguageChange}
        />
      }
      onOpenChange={setIsOpened}
    />
  );
};
