import React, { useState, useEffect } from "react";

import ReactGA from "../../utils/gtag";

import { useLangNavigate } from "../../hooks/useLangNavigate";

import {
  ConnectionsEnum,
  useConnectionType,
} from "../../providers/ConnectionTypeProvider";
import { usePayment } from "../../providers/PaymentProvider";

import { useAccount } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";

import { Tabs } from "./components/Tabs";
import { TabContent } from "./components/TabContent/TabContent";
import { SubmitButton } from "./components/SubmitButton";
import { Heading } from "./components/Heading/Heading";
import { CalculatePayment } from "./components/CalculatePayment/CalculatePayment";
import { PaymentInformation } from "./components/PaymentInformation";
import { FullScreenLoading } from "../../components/loadings/FullScreenLoading/FullScreenLoading";
import { FooterText } from "./components/FooterText";
import { Modal, Button } from 'antd';
import { validateDonation } from "../../utils/validations";

import {
  CURRENCIES_TRC,
  CURRENCIES_ERC,
  CurrencyType,
  CurrencyDataType,
  CurrencyEnum,
} from "../../constant";
import { useTranslation } from 'react-i18next';

const TAB_CONTENT_LIST: CurrencyType[] = [CURRENCIES_TRC, CURRENCIES_ERC];

const HomePage = () => {
  const navigate = useLangNavigate();
  const { changeConnectionType, isRedirecting, connectionType } =
    useConnectionType();
  const { t } = useTranslation();
  const { open } = useWeb3Modal();
  const { isConnected } = useAccount();
  const { quantity, handleQuantityChange } = usePayment();

  const [selectedTab, setSelectedTab] = useState<number>(1);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number | null>(
    null
  );
  const [selectedOptionValue, setSelectedOptionValue] =
    useState<CurrencyDataType | null>(null);

  const SELECTED_TAB = TAB_CONTENT_LIST[selectedTab - 1];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
    open();
  };

  useEffect(() => {
    ReactGA.event({
      action: "载入钱包页面",
      category: "钱包页面",
      label: `${selectedOptionValue?.id}`,
      value: parseInt(quantity),
    });
  }, []);

  useEffect(() => {
    if (isConnected) {
      changeConnectionType(ConnectionsEnum.Eth);
      navigate("/payment");
    }
  }, [isConnected, navigate, changeConnectionType]);

  const handleTabChange = (id: number) => {
    setSelectedTab(id);
    setSelectedOptionIndex(null);
    setSelectedOptionValue(null);
  };

  const handleOptionChange = (
    optionIndex: number,
    optionValue: CurrencyDataType
  ) => {
    setSelectedOptionIndex(optionIndex);
    setSelectedOptionValue(optionValue);
  };

  const handleValidate = () => {
    validateDonation(quantity, selectedOptionIndex, handleSubmit);
  };

  const handleSubmit = () => {
    ReactGA.event({
      action: "唤起钱包",
      category: "钱包页面",
      label: "钱包编号: " + selectedOptionValue?.id,
      value: parseInt(quantity),
    });

    handleDonation();
  };

  const handleDonation = () => {
    const currencyHandlers = {
      [CurrencyEnum.Erc]: handleErc,
      [CurrencyEnum.Trc]: handleTrc,
    };

    const selectedHandler = currencyHandlers[SELECTED_TAB.type];

    if (selectedHandler) selectedHandler();
  };

  const handleErc = () => {
    if (selectedOptionValue?.id == 10 || selectedOptionValue?.id == 9) {
      changeConnectionType(ConnectionsEnum.Eth);
      const queryParameterWithAmpersand = `&qty=${quantity}`;
      const encodedQuery = encodeURIComponent(queryParameterWithAmpersand);
      window.location.href = selectedOptionValue.deeplink! + encodedQuery;
    } else if (selectedOptionValue?.id == 6 || selectedOptionValue?.id == 7 || selectedOptionValue?.id == 11) {
      setIsModalOpen(true);
    } else{
      open();
    }
  };

  const handleTrc = () => {
    const queryParameterWithAmpersand = `&qty=${quantity}`;
    const encodedQuery = encodeURIComponent(queryParameterWithAmpersand);
    let deeplinkURL = "";

    if (selectedOptionValue?.params) {
      deeplinkURL =
        selectedOptionValue?.id === 7 || selectedOptionValue?.id === 13
          ? selectedOptionValue?.deeplink!.replace("donatenumber", quantity)
          : selectedOptionValue
              ?.deeplink!.replace("donatenumber", quantity)
              .replace("&", "%26");
    } else {
      deeplinkURL = selectedOptionValue?.deeplink + encodedQuery;
      console.log(deeplinkURL, "Deeplink created to redirect");
    }
    
    changeConnectionType(ConnectionsEnum.Trc);
    window.location.href = deeplinkURL
  };

  if (isRedirecting) {
    return <FullScreenLoading />;
  }

  return (
    <div className="homepage-container">
      <Heading />
      <CalculatePayment
        quantity={quantity}
        handleChange={handleQuantityChange}
      />
      <PaymentInformation suffix={SELECTED_TAB.type} />
      <TabContent
        tabContentData={SELECTED_TAB.data}
        selectedOptionIndex={selectedOptionIndex}
        handleOptionChange={handleOptionChange}
      />
      <Tabs handleChange={handleTabChange} selectedTab={selectedTab} />
      <SubmitButton onSubmit={handleValidate} />
      <FooterText />
      <Modal centered open={isModalOpen} footer={[
          <Button key="back" onClick={() => setIsModalOpen(false)}>
            {t('home.cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            {t('home.confirm')}
          </Button>
        ]}>
        <p>{t('home.tip')}</p>
      </Modal>
    </div>
  );
};

export default HomePage;
