import React from "react";


import { HomePageRedirect } from "../../routes/HomePageRedirect";

import {
  ConnectionsEnum,
  useConnectionType,
} from "../../providers/ConnectionTypeProvider";

import { EthereumContainer, TronContainer } from "./containers";
import { useLocation } from 'react-router';
import { useTranslation } from "react-i18next";

const PaymentPage = () => {
  const { connectionType, changeConnectionType } = useConnectionType();
  const { t } = useTranslation();
  
  const components = {
    [ConnectionsEnum.Eth]: EthereumContainer,
    [ConnectionsEnum.Trc]: TronContainer,
  };

  const location = useLocation();

  const decodedSearchParams = decodeURIComponent(location.search);
  const searchParams = new URLSearchParams(decodedSearchParams);
  const decodedHashParams = decodeURIComponent(location.hash.substring(2));
  const hashParams = new URLSearchParams(decodedHashParams);
  const channelId = searchParams.get("channelid") || hashParams.get("channelid");
  if (channelId) {
    changeConnectionType(ConnectionsEnum.Trc)
  }

  
  if (!connectionType) {
    return <HomePageRedirect />;
  }

  if (connectionType && components[connectionType]) {
    const Container = components[connectionType];

    return (
      <div className="payment-container">
        <Container />
        <div className="homepage-footer-text">{t("payment.tip")}</div>
      </div>
    );
  }

  return null;
};

export default PaymentPage;
