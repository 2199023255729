import React, { forwardRef } from "react";

import { ReactComponent as ArrowDownIcon } from "../../../assets/svgs/down-arrow.svg";

import "./styles.css";

interface LanguageSwitchTriggerProps {
  label: string;
  flagSrc: string;
}

export const LanguageSwitchTrigger = forwardRef<
  HTMLButtonElement,
  LanguageSwitchTriggerProps
>(({ label, flagSrc, ...rest }, ref) => {
  return (
    <button {...rest} ref={ref} className="language-trigger">
      <div className="language-trigger-content">
        <img className="language-flag" src={flagSrc} alt="Flag Source" />
        <span className="language-label">{label}</span>
      </div>
      <ArrowDownIcon className="arrow-down-icon"/>
    </button>
  );
});

LanguageSwitchTrigger.displayName = "LanguageSwitchTrigger";
