import { useCallback } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

export const useLangNavigate = () => {
  const navigation = useNavigate();
  const { i18n } = useTranslation();

  const navigate = useCallback(
    (url: string) => {
      navigation(url);
      // navigation("/" + i18n.language + url);
    },
    [navigation, i18n]
  );

  return navigate;
};
