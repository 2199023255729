import React, { ReactNode } from "react";

import * as Popover from "@radix-ui/react-popover";

import "./styles.css";

export interface PopperProps {
  open: boolean;
  trigger: ReactNode;
  content: ReactNode;
  sideOffset?: number;
  align?: "center" | "start" | "end";
  onOpenChange: (state: boolean) => void;
}

export const Popper = ({
  open,
  trigger,
  content,
  sideOffset = 5,
  align = "center",
  onOpenChange,
}: PopperProps) => {
  return (
    <Popover.Root open={open} onOpenChange={onOpenChange}>
      <Popover.Trigger asChild>{trigger}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          className="PopoverContent"
          sideOffset={sideOffset}
          align={align}
        >
          {content}
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
