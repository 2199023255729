import { t } from "i18next";

import { toastError } from "../toast";

const isValidAndPositiveNumber = (input) => {
  const positiveNumberRegex = /^[1-9]\d*(\.\d+)?$/;
  return positiveNumberRegex.test(input);
};

export const validateDonation = (
  quantity: string,
  selectedOption: number | null,
  handleSubmit: () => void
) => {
  if (isNaN(+quantity) || !isValidAndPositiveNumber(parseFloat(quantity))) {
    toastError(t("alerts.valid_number"));
  } else if (+quantity <= 0 || quantity === undefined) {
    toastError(t("alerts.bigger_zero"));
  } else if (selectedOption === null) {
    toastError(t("alerts.select_one_wallet"));
  } else {
    handleSubmit();
  }
};

export const validatePayment = (quantity: string, handleSubmit: () => void) => {
  if (isNaN(+quantity) || !isValidAndPositiveNumber(parseFloat(quantity))) {
    toastError(t("alerts.valid_number"));
  } else if (+quantity <= 0 || quantity === undefined) {
    toastError(t("alerts.bigger_zero"));
  } else {
    handleSubmit();
  }
};
