import React from "react";

import { LanguageSwitchPopper } from "./LanguageSwitchPopper";

import "./styles.css";

export const LanguageSwitch = () => {
  return (
    <div className="language-switch-wrapper">
      <LanguageSwitchPopper />
    </div>
  );
};
