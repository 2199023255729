import React from "react";

import { useTranslation } from "react-i18next";

import "./styles.css";

export const PaymentHeading = () => {
  const { t } = useTranslation();

  return (
    <div className="payment-heading-container">
      <h1>
        <span>YosuPay</span> {t("payment.heading")}
      </h1>
    </div>
  );
};
